










































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { IBuildingCreate } from "@/interfaces";
import {
  dispatchGetBuilding,
  dispatchUpdateBuilding,
  dispatchGetFilesByBuilding,
  dispatchDeleteFile,
  dispatchDeleteBuilding,
} from "@/store/main/actions";
import {
  readBuilding,
  readUploadedFiles,
  readDevices,
  readGroups,
} from "@/store/main/getters";
import SlVueTree from "sl-vue-tree";

@Component({
  components: {
    SlVueTree,
  },
})
export default class Edit extends Vue {
  public loading = true;
  public valid = false;
  public dialog = false;
  public buildingDialog = false;

  public name = "";
  public id = 0;

  public pagination = {
    ascending: true,
    sortBy: "name",
    rowsPerPage: 25,
    page: 1,
  };
  public headers = [
    {
      text: "File Name",
      sortable: true,
      value: "file_name",
      align: "left",
    },
    {
      text: "Floor name",
      sortable: true,
      value: "full_name",
      align: "left",
    },
    {
      text: "Floor number",
      sortable: true,
      value: "short_name",
      align: "left",
    },
        {
      text: "Position",
      sortable: true,
      value: "position",
      align: "left",
    },
    {
      text: "Fixture Layer",
      sortable: true,
      value: "fixture_layer_name",
      align: "left",
    },
    {
      text: "Floor Layer",
      sortable: true,
      value: "floor_layer_name",
      align: "left",
    },
    {
      text: "Sensor Layer",
      sortable: true,
      value: "sensor_layer_name",
      align: "left",
    },
    {
      text: "Actions",
      sortable: false,
      value: "id",
      align: "center",
    },
  ];

  public building: IBuildingCreate = {
    name: "",
    address: "",
    project_id: 0,
  };

  public reset() {
    this.building = <IBuildingCreate>{ ...this.getBuilding };
    this.$validator.reset();
  }

  get getProjectId() {
    return this.building?.project_id;
  }

  get getBuilding() {
    return readBuilding(this.$store);
  }

  get files() {
    return readUploadedFiles(this.$store);
  }

  public cancel() {
    this.$router.back();
  }

  get buildingId() {
    return Number(this.$router.currentRoute.params.id);
  }

  public async mounted() {
    await dispatchGetBuilding(this.$store, this.buildingId);
    this.building = <IBuildingCreate>{ ...readBuilding(this.$store) };
    if (this.getBuilding) {
      await dispatchGetFilesByBuilding(this.$store, this.buildingId);
    }
    this.loading = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      await dispatchUpdateBuilding(this.$store, {
        buildingId: this.buildingId,
        data: this.building,
      });
      this.$router.back();
    }
  }

  public async confirm() {
    this.dialog = false;
    await dispatchDeleteFile(this.$store, Number(this.id));
    await dispatchGetFilesByBuilding(this.$store, this.buildingId);
  }

  public async buildingConfirm() {
    this.buildingDialog = false;
    await dispatchDeleteBuilding(this.$store, Number(this.buildingId));
    this.$router.back();
  }
}
