<template>
  <v-list subheader>
    <v-subheader v-if="group">{{ group.name }}</v-subheader>
    <v-btn v-if="group" @click="back">Back</v-btn>
    <v-btn @click="newGroup">New Group</v-btn>
    <v-textarea
      label="Position"
      v-model="positions"
    ></v-textarea>
    <v-btn @click="draw">Draw</v-btn>
    <div v-if="error">{{ error }}</div>
  </v-list>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      default: null,
    },
    onBack: {
      default: null,
    },
    onNewGroup: {
      default: null,
    },
    onDraw: {
      default: null,
    }
  },

  data() {
    return {
      selectedValue: '',
      positions: '',
      error: '',
    };
  },

  methods: {
    back() {
      if (this.onBack) {
        this.onBack();
      }
    },
    newGroup() {
      if (this.onNewGroup) {
        this.onNewGroup();
      }
    },
    draw() {
      if (this.onDraw) {
        try {
          this.onDraw(JSON.parse(this.positions));
          this.error = '';
        } catch (e) {
          this.error = 'Invalid JSON';
        }
      }
    },
  },
};
</script>

<style scoped>
.root {
  height: 100%;
  max-height: 100%;
  width: 300px;
}
</style>
