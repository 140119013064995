<template>
  <v-layout row wrap>
    <v-flex xs10
      ><DxfViewer
        ref="viewer"
        :dxfUrl="dxfUrl"
        :file="file"
        :areaMode="areaMode"
        :groupedDevices="groupedDevices"
        :visibleGroups="visibleGroups"
        :visibleDevices="visibleDevices"
        :fonts="[mainFont, aux1Font, aux2Font]"
        :deviceType="deviceType"
        :testPositions="testPositions"
        @dxf-loaded="_OnLoaded"
        @dxf-cleared="_OnCleared"
        :onShapeClick="onShapeClick"
        :onClick="onClick"
        :onHideHeatGrid="onHideHeatGrid"
        :onDblclick="_OnDblClick"
    /></v-flex>
    <v-flex xs2>
      <div>
        <v-checkbox
            v-model="areaMode"
            :label="`Area edit`"
          ></v-checkbox>
        <v-btn @click="addArea">Add Area</v-btn>
      </div>
      <ObjectDetails
        :type="type"
        :object="selectedObject"
        @typeChanged="_OnTypeChanged"
        @submitted="_OnSubmit"
      />
      <GroupsList :group="this.activeGroup" :onDraw="_OnDraw" :onBack="_OnBack" :onNewGroup="_OnNewGroup"/>
    </v-flex>
  </v-layout>
</template>

<script>
import DxfViewer from "@/components/DxfViewer";
import Vue from "vue";
import mainFont from "@/assets/fonts/Roboto-LightItalic.ttf";
import aux1Font from "@/assets/fonts/NotoSansDisplay-SemiCondensedLightItalic.ttf";
import aux2Font from "@/assets/fonts/NanumGothic-Regular.ttf";
import GroupsList from "@/components/GroupsList";
import ObjectDetails from "@/components/ObjectDetails";

import {
  dispatchGetGroups,
  dispatchGetDevice,
  dispatchUpdateDevice,
  dispatchDeleteDevice,
  dispatchGetDevicesByFile,
  dispatchGetGroupsByFile,
  dispatchGetFloorAreasByFile
} from "@/store/main/actions";
import { readGroups, readDevices, readFile, readAreas } from "@/store/main/getters";

export default {
  components: { DxfViewer, GroupsList, ObjectDetails },

  props: {
    dxfUrl: {
      type: String,
    },
    file: {
      type: Object,
    },
  },

  data() {
    return {
      layers: null,
      type: "single",
      areaMode: false,
      selectedObject: null,
      groups: [],
      activeGroup: null,
      groupedDevices: [],
      visibleGroups: [],
      visibleDevices: [],
      deviceType: "fixture",
      childGroups: [],
      childDevices: [],
      history: [],
      testPositions: [],
    };
  },

  methods: {
    _OnLoaded() {
      // const layers = this.$refs.viewer.GetViewer().GetLayers();
      // layers.forEach((lyr) => Vue.set(lyr, "isVisible", true));
      // this.layers = layers;
    },

    _OnCleared() {
      this.groups = [];
      this.devices = [];
      this.activeGroup = null;
    },

    _OnDraw(value) {
      console.log(value);
      this.testPositions = value;
    },

    async _OnSubmit() {
      await dispatchGetDevicesByFile(
        this.$store,
        Number(this.$router.currentRoute.params.id)
      );
      await dispatchGetGroupsByFile(
        this.$store,
        Number(this.$router.currentRoute.params.id)
      );
      await dispatchGetFloorAreasByFile(
        this.$store,
        Number(this.$router.currentRoute.params.id)
      );
      this.refreshData();
    },

    _OnTypeChanged(value) {
      // this.deviceType = value;
    },

    _OnAddGroup() {
      this.groups = [];
      this.devices = [];
      this.activeGroup = null;
    },

    _OnToggleLayer(id, group) {
      return;
      if (!this.activeGroup) {
        this.activeGroup = group;
      } else {
        if (this.activeGroup.id == id) {
          this.activeGroup = null;
        } else {
          this.activeGroup = group;
        }
      }

      if (this.activeGroup == null) {
        // Khong chon group nao thi xem nhu dang xem het

        // Can tinh lai cac device nao da co group roi thi bo vao day de an di
        this.groupedDevices = [];

        // Can tinh lai de lay ra cac group to tien. ko hien cac group con
        this.visibleGroups = [];

        // Cai nay cu de no null.
        this.visibleDevices = null;

        this.refreshData();
      } else {
        // Khi chon xem 1 group thi can phai
        // Cai nay cu de []
        this.groupedDevices = [];

        // Nhu the nay la dc roi
        this.visibleGroups = group.group_ids;

        // Nhu the nay la dc roi
        this.visibleDevices = group.device_ids;
      }
    },

    _OnBack() {
      this.selectedObject = null;
      if (this.history.length == 0) {
        return;
      }
      this.activeGroup = this.history.pop();

      if (this.activeGroup) {
        this.groupedDevices = [];

        // Nhu the nay la dc roi
        this.visibleGroups = this.activeGroup.group_ids;

        // Nhu the nay la dc roi
        this.visibleDevices = this.activeGroup.device_ids;
      } else {
        this.visibleDevices = null;
        this.refreshData();
      }
    },

    _OnNewGroup() {
      this.type = "single";
      const file = this.getFile();
      this.selectedObject = {
        'name': '',
        'building_id': file.building_id,
        'project_id': file.project_id,
        'file_id': file.id,
      }
    },

    _OnDblClick(group) {
      console.log(this.history, group)
      this.history.push(this.activeGroup)
      this.activeGroup = group;

      if (group) {
        this.groupedDevices = [];

        // Nhu the nay la dc roi
        this.visibleGroups = group.group_ids;

        // Nhu the nay la dc roi
        this.visibleDevices = group.device_ids;
      }
    },

    addArea() {
      console.log('aa');
      this.areaMode = true;
      this.$refs.viewer.turnOnAddMode();
    },

    refreshData() {
      this.groups = [...this.getGroups()];
      this.devices = [...this.getDevices()];
      this.areas = [...this.getAreas()];

      const childGroups = [];
      const childDevices = [];

      const visibleGroups = [];

      if (this.activeGroup) {
        const groupsByKey = this.groups.reduce(
          (a, v) => ({ ...a, [`g-${v.id}`]: v }),
          {}
        );
        console.log(groupsByKey);
        for (let i = 0; i < this.history.length; i++) {
          if (this.history[i]) {
            const name = `g-${this.history[i].id}`;
            if (groupsByKey[name]) {
              this.history[i] = groupsByKey[name];              
            }
          }
        }
        const name2 = `g-${this.activeGroup.id}`;
        if (groupsByKey[name2]) {
          this.activeGroup = groupsByKey[name2];
        }
        this.visibleDevices = this.activeGroup.device_ids;
        this.visibleGroups = this.activeGroup.group_ids;
      } else {
        this.groups.forEach((group) => {
          childGroups.push(...group.group_ids);
          childDevices.push(...group.device_ids);
        });
        this.groups.forEach((group) => {
          if (!childGroups.includes(group.id)) {
            visibleGroups.push(group.id);
          }
        });

        this.visibleDevices = null;
        this.groupedDevices = [...childDevices];
        this.visibleGroups = [...visibleGroups];
      }
    },

    getFile() {
      return readFile(this.$store);
    },

    getGroups() {
      return readGroups(this.$store);
    },

    getDevices() {
      return readDevices(this.$store);
    },

    getAreas() {
      return readAreas(this.$store);
    },

    onShapeClick(id, object) {
      this.selectedObject = object;
    },

    async onHideHeatGrid(sensor, selectGrids) {
      if (sensor) {
      const sensorObj = sensor.object.exData;
        await dispatchUpdateDevice(this.$store, {
          deviceId: sensorObj.id,
          data:{'selected_cells': selectGrids}
        });
      }
    },
    onClick(type, objects1, objects2) {
      this.type = type;
      if (type == "single") {
        if (
          this.selectedObject &&
          objects1 &&
          objects1.id == this.selectedObject.id
        ) {
          if (objects1.type != 'area') {
            this.selectedObject = null;
          }
          else {
            this.selectedObject = objects1;
          }
        } else {
          this.selectedObject = objects1;
        }
      } else {
        this.selectedObject = objects1;
      }
    },
  },

  async mounted() {
    // Goi api va sau do lay du lieu gan vao groups
    await dispatchGetGroupsByFile(
      this.$store,
      Number(this.$router.currentRoute.params.id)
    );
    // Goi api va sau do lay du lieu gan vao devices
    await dispatchGetDevicesByFile(
      this.$store,
      Number(this.$router.currentRoute.params.id)
    );
    // Get floor areas base on file id
    await dispatchGetFloorAreasByFile(
      this.$store,
      Number(this.$router.currentRoute.params.id)
    );
    this.refreshData();
    // Sau khi co du lieu thi tinh toan lai groupedDevices, visibleGroups, visibleDevices
  },

  created() {
    this.mainFont = mainFont;
    this.aux1Font = aux1Font;
    this.aux2Font = aux2Font;
  },
};
</script>

<style scoped>
.root .layersCol {
  border-left: #dbdbdb solid 1px;
}
</style>
