




import { env } from '@/env';
import ViewerPage from "@/components/ViewerPage.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { readFile, readDevices, readGroups } from "@/store/main/getters";
import {
  dispatchGetFile,
  dispatchLoginSample
} from "@/store/main/actions";
import { IFileDataUpdate } from "@/interfaces";

@Component({
  components: {
    ViewerPage,
  },
})
export default class FloorplanSample extends Vue {
  public loading = true;
  public nodes: any[] = [];
  public childGroups = [];
  public childDevices: any[] = [];

  // get download_url() {
  //   return `${apiUrl}/api/v1/files_sample`;
  // }

  get devices() {
    return readDevices(this.$store);
  }

  get groups() {
    return readGroups(this.$store);
  }

  get getProjectId() {
    return this.getFile?.project_id;
  }

  get getFile() {
    return readFile(this.$store);
  }

  get layer_names() {
    return this.getFile?.layer_names;
  }

  public file: IFileDataUpdate = {
    fixture_layer_name: "",
    floor_layer_name: "",
    sensor_layer_name: "",
    full_name: "",
    short_name: "",
    position: 0,
    display_name: "",
  };

  get download_url() {
    const file = this.getFile;
    if (!file) {
      return "";
    }
    return file.download_url;
  }

  get fileId() {
    return Number(this.$router.currentRoute.params.id);
  }
  
  public async mounted() {
    let username = '';
    let password = '';
    if (env === 'production') {
      username = 'summasample'
      password = 'manifera@2022';
    } else if (env === 'staging') {
      username = 'testfloorplan'
      password = 'manifera@2022';
    } else {
      username = 'admin@api-server.test'
      password = '1';
    }
    await dispatchLoginSample(this.$store, { username: username, password: password });
    await dispatchGetFile(this.$store, this.fileId);
    this.file = <IFileDataUpdate>{ ...this.getFile };
    this.loading = false;
  }
}
