










































































































































































































































import ViewerPage from "@/components/ViewerPage.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  readFile,
  readDevices,
  readGroups,
  readBuildings,
} from "@/store/main/getters";
import {
  dispatchUpdateFile,
  dispatchGetFile,
  dispatchGetGroupsByFile,
  dispatchGetDevicesByFile,
  dispatchGetBuildings,
  dispatchDeleteFile,
  dispatchDeleteAnalyticPeople,
  dispatchDeleteAnalyticHeatmap,
  dispatchDeleteAnalyticPeopleTracking
} from "@/store/main/actions";
import { IFileDataUpdate } from "@/interfaces";
import SlVueTree from "sl-vue-tree";

@Component({
  components: {
    ViewerPage,
    SlVueTree,
  },
})
export default class File extends Vue {
  public loading = true;
  public nodes: any[] = [];
  public childGroups = [];
  public childDevices: any[] = [];
  public dialog = false;
  public dialogHeatmap = false;
  public dialogPeople = false;
  public dialogPeopleTracking = false;

  get devices() {
    return readDevices(this.$store);
  }

  get groups() {
    return readGroups(this.$store);
  }

  get getProjectId() {
    let project_id: number = this.getFile?.project_id as number;
    return project_id;
  }

  get getFile() {
    return readFile(this.$store);
  }

  get layer_names() {
    return this.getFile?.layer_names;
  }

  public file: IFileDataUpdate = {
    fixture_layer_name: "",
    floor_layer_name: "",
    sensor_layer_name: "",
    full_name: "",
    short_name: "",
    position: 0,
    display_name: "",
  };

  get download_url() {
    const file = this.getFile;
    if (!file) {
      return "";
    }
    return file.download_url;
  }

  public getGroup(id: any) {
    return this.groups.find((x) => x.id === id);
  }

  get buildings() {
    return readBuildings(this.$store);
  }

  // public getDevice(id: any){
  //   return this.devices.find(x => x['id'] === id);
  // }

  get fileId() {
    return Number(this.$router.currentRoute.params.id);
  }

  public parseGroup(id: any, device_id = null) {
    if (device_id) {
      const device = [];
      if (!device) {
        return null;
      }
      return {
        isLeaf: true,
        isDraggable: false,
        isExpanded: false,
        title: device["block_name"],
      };
    }
    const group = this.getGroup(id);
    if (!group) {
      return null;
    }
    group["data"] = {
      id: id,
    };
    // group['groups'] = [];
    group["isLeaf"] = true;
    group["isExpanded"] = false;
    group["isDraggable"] = false;
    group["title"] = group.name;
    if (group.group_ids.length > 0 || group.device_ids.length > 0) {
      group["children"] = [];
      // for(let i = 0; i < group.group_ids.length; i++) {
      group.group_ids.forEach((group_id) => {
        let child = this.parseGroup(group_id);
        if (child) {
          group["isLeaf"] = false;
          group["isExpanded"] = true;
          group["children"].push(child);
        }
      });

      // group['groups'][i] = this.parseGroup(group.group_ids[i]);

      // for(let i = 0; i < group.device_ids.length; i++) {
      //   let data = this.parseGroup(id, group.device_ids[i]);
      //   if (data){
      //     group['isLeaf'] = false;
      //     group['isExpanded'] = true;
      //     // group['children'].push(data);
      //   }
      // }
    } else {
      // isleaf
      group["isLeaf"] = true;
    }
    return group;
  }

  public groupData() {
    this.nodes = [];
    if (this.groups.length > 0) {
      this.groups.forEach((group) => {
        this.childGroups.push(...group.group_ids);
        // this.childDevices.push(...group.device_ids);
      });

      this.groups.forEach((group) => {
        if (!this.childGroups.includes(group.id as never)) {
          this.nodes.push(this.parseGroup(group.id));
        }
      });

      // let devices = [];
      // if (this.devices.length>0){
      //   this.devices.forEach(device => {
      //     if (!this.childDevices.includes(device['id'] as never)){
      //       devices.push({
      //         'title': device['block_name'],
      //         'isDraggable': false as never,
      //         'isExpanded': false  as never,
      //         'isLeaf': true  as never,
      //         'data': {
      //           id: device['id'],
      //           type: device['type'],
      //           serial: device['serial_number'],
      //           channels: device['channels']
      //         }
      //       } as never);
      //     }
      //   });

      //   this.nodes.push({
      //     'title': 'Unassigned',
      //     'isDraggable': false,
      //     'isExpanded': false,
      //     'children': devices,
      //     'isLeaf': false
      //   });
      // }
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      await dispatchUpdateFile(this.$store, {
        id: this.fileId,
        file: this.file,
      });
      this.$router.go(0);
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async mounted() {
    await dispatchGetFile(this.$store, this.fileId);

    this.file = <IFileDataUpdate>{ ...this.getFile };
    if (this.getFile) {
      await dispatchGetGroupsByFile(this.$store, this.fileId);
      await dispatchGetBuildings(this.$store, this.getProjectId);
      this.groupData();
    }
    this.loading = false;
  }

  public async confirm() {
    this.dialog = false;
    await dispatchDeleteFile(this.$store, Number(this.fileId));
    this.cancel();
  }

  public async heatmapConfirm() {
    this.dialogHeatmap = false;
    await dispatchDeleteAnalyticHeatmap(this.$store, Number(this.fileId));
  }

  public async peopleConfirm() {
    this.dialogPeople = false;
    await dispatchDeleteAnalyticPeople(this.$store, Number(this.fileId));
  }

  public async peopleTrackingConfirm() {
    this.dialogPeopleTracking = false;
    await dispatchDeleteAnalyticPeopleTracking(this.$store, Number(this.fileId));
  }
}
