<template>
  <v-form class="pa-3" v-if="object">
    <div>Details</div>
    <!-- <template :is="object">
    {{ object }}
  </template> -->
    <v-layout row wrap>
      <v-select
        v-if="data.type != 'area'"
        v-model="data.parent_id"
        :items="groups"
        label="Select a parent group (optional)"
        item-value="id"
        item-text="name"
        required
        dense
        :clearable="true"
      ></v-select>
      <template v-if="type == 'single'">
        <v-flex v-if="object" xs12 sm12 md12>
          <v-text-field v-model="data.id" label="Id" disabled></v-text-field>
          <v-text-field v-model="data.name" label="Name"></v-text-field>
          <template v-if="data.type == 'area'">
            <v-text-field v-model="data.x" label="X"></v-text-field>
            <v-text-field v-model="data.y" label="Y"></v-text-field>
            <v-text-field v-model="data.width" label="Width"></v-text-field>
            <v-text-field v-model="data.height" label="Height"></v-text-field>
          </template>
        </v-flex>
        <template v-if="object && deviceTypes.includes(data.type)">
          <v-flex xs12 sm12 md12>
            <v-text-field
              v-model="data.serial_number"
              label="Serial"
            ></v-text-field>
          </v-flex>
          <v-select
            v-model="data.selectedChannel"
            :items="channels"
            label="Select a channel (optional)"
            dense
            :clearable="true"
          ></v-select>
        </template>
        <v-select
          v-if="object && deviceTypes.includes(data.type)"
          :items="deviceTypes"
          v-model="data.type"
          label="Select a device type"
          item-value="id"
          item-text="name"
          required
        ></v-select>
        <template v-if="data.type == 'sensor'">
          <v-flex xs12 sm12 md12>
            <v-text-field
              v-model="data.ceil_height"
              label="Ceil height"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-text-field v-model="data.angle" label="Angle"></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-text-field v-model="data.rotation" label="Rotation"></v-text-field>
          </v-flex>
        </template>
      </template>
      <v-btn v-if="object" @click="submit">{{!object.id ? (data.type != 'area' ? 'Add Group': 'Add Area') : 'Update' }}</v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import { readGroups, readDevice } from "@/store/main/getters";
import {
  dispatchUpdateDevice,
  dispatchUpdateGroup,
  dispatchCreateGroup,
  dispatchCreateFloorArea,
  dispatchDeleteFloorArea,
  dispatchUpdateFloorArea
} from "@/store/main/actions";
export default {
  props: {
    type: {
      default: "single",
    },
    object: {
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      data: {
        parent_id: "",
        selectedChannel: "",
      },
      deviceTypes: ["fixture", "sensor"],
      groupTypes: ["Group", "Layer"],
      groups: [],
      channels: ["ch1", "ch2", "ch3", "fusion"],
      old_type: "",
      old_parent_id: "",
    };
  },

  watch: {
    object(newObject) {
      let groups = readGroups(this.$store);
      if (newObject) {
        if (Array.isArray(newObject)) {
          this.data = {
            parent_id: "",
            selectedChannel: "",
          };
          return;
        }
        this.data = { ...newObject };
        if (newObject.type == "fixture" || newObject.type == "sensor") {
          this.groups = groups;
          this.data.selectedChannel =
            newObject.channels && newObject.channels.length > 0
              ? newObject.channels[0]
              : "";
          this.data.name = newObject.block_name;
          if (this.data.groups?.length) {
            const parent = this.groups.filter((e) => {
              return e.id == this.data.groups[0].id;
            });
            if (parent.length) {
              this.data.parent_id = parent[0].id;
              this.old_parent_id = this.data.parent_id;
            }
          }
        } else if (newObject.type == 'area') { 
          console.log('area')
        } else {
          let arrSelect = [];
          groups.forEach((group) => {
            if (group.group_ids.includes(newObject.id)) {
              this.data.parent_id = group.id;
              this.old_parent_id = this.data.parent_id;
            }
          });
          const geyChildOfNewObject = (groups, newObject) => {
            let group = groups.find(
              (group) => group.id == newObject.id && group.group_ids.length > 0
            );
            if (group) { 
              let listChildId = group.group_ids;
              arrSelect = [...arrSelect, ...listChildId];
              let arrChild = groups.filter((group) =>
                listChildId.includes(group.id)
              );
              arrChild.forEach((group) => {
                geyChildOfNewObject(groups, group);
              });
            }
          };
          geyChildOfNewObject(groups, newObject);
          groups = groups.filter(
            (group) => !arrSelect.includes(group.id) && group.id != newObject.id
          );
          this.groups = groups;
        }
      } else {
        this.data = {
          parent_id: "",
        };
      }
    },
  },

  methods: {
    async remove() {
      if (this.data.type == "area") {
        await dispatchDeleteFloorArea(this.$store, this.data.id);
        this.$emit("submitted");
      }
    },
    async submit() {
      if (await this.$validator.validateAll()) {
        let listDevice = [];
        if (this.type == "single") {
          const submmitData = {};
          if (this.data.type == 'area') {
            // create
            if (!this.object.id){
              submmitData.name = this.data.name;
              submmitData.x = parseFloat(this.data.x);
              submmitData.y = parseFloat(this.data.y);
              submmitData.width = parseFloat(this.data.width);
              submmitData.height = parseFloat(this.data.height);
              submmitData.file_id = Number(this.$router.currentRoute.params.id);
              await dispatchCreateFloorArea(this.$store, submmitData);
              this.$emit("submitted");
            }else {
              // update
              submmitData.id = this.data.id;
              submmitData.name = this.data.name;
              submmitData.x = parseFloat(this.data.x);
              submmitData.y = parseFloat(this.data.y);
              submmitData.width = parseFloat(this.data.width);
              submmitData.height = parseFloat(this.data.height);
              submmitData.file_id = Number(this.$router.currentRoute.params.id);
              await dispatchUpdateFloorArea(this.$store, {
                floorAreaId: this.data.id,
                data: submmitData,
              });
              this.$emit("submitted");
            }
            
            return;
          } 
          
          submmitData.parent_id = this.data.parent_id;
          if (!this.object.id && this.data.name) {
            submmitData.name = this.data.name;
            submmitData.type = "Group";
            submmitData.project_id = this.object.project_id;
            submmitData.building_id = this.object.building_id;
            submmitData.file_id = this.object.file_id;

            await dispatchCreateGroup(this.$store, submmitData);
            this.$emit("submitted");
          } else {
            submmitData.old_parent_id = this.old_parent_id;
            if (["sensor", "fixture"].includes(this.data.type)) {
              submmitData.block_name = this.data.name;
              submmitData.serial_number = this.data.serial_number;
              submmitData.channels = [this.data.selectedChannel] || [];
              submmitData.angle = this.data.angle;
              submmitData.ceil_height = Number(this.data.ceil_height);
              submmitData.serial_number = this.data.serial_number;
              submmitData.selected_cells = this.data.selected_cells || [];
              submmitData.type = this.data.type;
              submmitData.rotation = this.data.rotation;

              await dispatchUpdateDevice(this.$store, {
                deviceId: this.object.id,
                data: submmitData,
              });
              this.$emit("submitted");
            }
            else if (["Group"].includes(this.data.type)) {
              submmitData.name = this.data.name;
              await dispatchUpdateGroup(this.$store, {
                groupId: this.object.id,
                data: submmitData,
              });
              this.$emit("submitted");
            }
          }
        } else {
          listDevice = JSON.parse(JSON.stringify(this.object));
          for (let device of listDevice) {
            device.old_parent_id = device.groups[0]
              ? device.groups[0].id
              : undefined;
            device.parent_id = this.data.parent_id;
            await dispatchUpdateDevice(this.$store, {
              deviceId: device.id,
              data: device,
            });
          }
          this.$emit("submitted");
        }
        // if (this.old_type) {
        //   this.$router.go();
        // }
      }
    },
  },
};
</script>
